import pluralize from "pluralize";
import { ApiDataMixin } from "@cp/store/mixins";
import { required, email } from "@cp/utils/rules";
import { unique } from "@cp/utils/arrayUtils";

import { Model } from "@/store/mixins/Model";
import search from "@/store/modules/search";

const employeeSearch = search.register("employees");
const locationSearch = search.register("locations");
const positionSearch = search.register("positions");

export const employee = new Model({
  module: "employee",
  urlKey: "users",
  compoundId: true,
  table: {
    headers: [
      { text: "", value: "alert", sortable: false, width: "20px" },
      {
        text: "Name",
        value: "full_name",
        width: "auto",
        cellClass: "linkedCell",
      },
      {
        text: "Position",
        value: "position",
        width: "15%",
      },
      {
        text: "Location",
        value: "location",
        width: "15%",
      },
      {
        text: "Managers",
        value: "managers",
        sortable: false,
        width: "15%",
        cellClass: "text-truncate",
      },
      {
        text: "Status",
        value: "status_id",
        width: "100px",
      },
    ],
    filters: [
      "q_text",
      {
        label: "General",
        type: "select",
        key: "general",
        items: [
          { label: "Active", value: "active" },
          { label: "Inactive", value: "archived" },
          { label: "No Start Date", value: "missing_start_date" },
          { label: "No Location", value: "missing_locations" },
          { label: "No Position", value: "missing_position" },
          {
            label: "No Manager or subordinates",
            value: "no_managers_or_subordinates",
          },
          { label: "Opted out of emails", value: "opted_out" },
        ],
        initialValue: "active",
      },
      {
        label: "Locations",
        type: "sub_query",
        key: "locations",
        multiple: true,
      },
      {
        label: "Regions",
        type: "sub_query",
        key: "regions",
        multiple: true,
      },
      {
        label: "Positions",
        type: "sub_query",
        key: "positions",
        multiple: true,
      },
      {
        label: "Reporting Groups",
        type: "sub_query",
        key: "reporting_groups_by_locations",
        multiple: true,
      },
      {
        label: "Lifecycle Status",
        type: "sub_query",
        key: "lifecycle_statuses",
      },
      {
        label: "Roles",
        type: "sub_query",
        key: "roles",
      },
    ],
    useGlobalBulkActions: false,
    bulkActions: [
      {
        name: "restore",
        showIf: selected => selected.some(({ status_id }) => status_id === 2),
        explanation:
          "This will enable their login, and allow them to use Swift Bunny apps",
        successMessage: ({ ids }, module) =>
          `${pluralize(module.noun, ids.length, true)} restored!`,
      },
      {
        name: "archive",
        showIf: selected => selected.some(({ status_id }) => status_id === 1),
        explanation:
          "This will disable their login, and prevent them from using any Swift Bunny apps",
        successMessage: ({ ids }, module) =>
          `${pluralize(module.noun, ids.length, true)} archived!`,
      },
      {
        name: "send_welcome_emails",
        article: "to",
        explanation:
          "This will send a welcome email to all selected employees, inviting them to login to Swift Bunny!",
        successMessage: ({ ids }) =>
          `${pluralize("email", ids.length, true)} sent!`,
      },
      {
        name: "update_managers",
        label: "Bulk assign manager",
        explanation: "Assign a new manager to these employees:",
        fields: {
          manager_ids: { rules: [required], multiple: true },
          update_method: {
            label: "Remove previous assignments",
            initialValue: "append",
          },
        },
        modalComponent: "bluk-set-employees-manager",
        successMessage: "Managers updated!",
      },
      {
        name: "update_positions",
        label: "Bulk assign position",
        explanation: "Assign a new position to these employees:",
        fields: {
          position_id: { rules: [required] },
        },
        modalComponent: "bluk-set-employees-position",
        successMessage: "Position updated!",
      },
      {
        name: "assign_as_location_managers",
        label: "Assign as location's manager",
        explanation:
          "This will make the employee the location manager for the location they are assigned to",
        dataFn: selected => ({ ids: selected.map(x => x.rawId) }),
        successMessage: "Location manager updated!",
      },
      {
        name: "assign_as_maintenance_managers",
        label: "Assign as location's maintenance manager",
        explanation:
          "This will make the employee the maintenance manager for the location they are assigned to",
        successMessage: "Location maintenance manager updated!",
      },
    ],
  },
  form: {
    initialValue: {
      id: "",
      rawId: "",
      first_name: "",
      last_name: "",
      full_name: "",
      role_ids: [],
      client_id: "",
      position_id: "",
      position: "",
      location_id: "",
      location: "",
      user_status_id: 1,
      email: "",
      personal_email: "",
      cell_phone: "",
      start_date: "",
      locale: "en",
      last_archived_at: "",
      created_at: "",
      updated_at: "",
      managers: [],
      manager_ids: [],
      reference_id: "",
      status_id: 1,
      archived_at: "",
      client_is_admin: false,
      opt_out: false,
      client_has_areas: true,
      transformations: [],
      service_access: [],
      region: {},
      avatar: { url: null, thumb: { url: null } },
    },
    fields: {
      first_name: {
        rules: [required],
      },
      last_name: {
        rules: [required],
      },
      location_id: {
        label: "Location",
        type: "api-autocomplete",
        searchModule: locationSearch,
      },
      position_id: {
        label: "Position",
        type: "api-autocomplete",
        searchModule: positionSearch,
      },
      reference_id: {
        label: "Employee ID",
      },
      hire_date: {
        type: "date",
      },
      start_date: {
        label: "Hire Date",
        type: "date",
        rules: [required],
      },
      cell_phone: {},
      email: {
        rules: [required, email],
      },
      manager_ids: {
        label: "Managers",
        type: "api-autocomplete",
        searchModule: employeeSearch,
        multiple: true,
      },
      personal_email: {
        rules: [email],
      },
      locale: {
        label: "Language Preference",
        type: "single-select",
        items: [
          { text: "English", value: "en" },
          { text: "Spanish", value: "es" },
        ],
      },
      opt_out: {
        label: "Opt Out of Surveys",
        type: "checkbox",
      },
      role_ids: {},
    },
    fieldOrder: [
      "first_name",
      "last_name",
      "location_id",
      "position_id",
      "reference_id",
      "start_date",
      "cell_phone",
      "email",
      "manager_ids",
      "personal_email",
      "locale",
      "opt_out",
    ],
  },
  search: employeeSearch,
  detailTabs: ["general", "settings", "history"],
});

export const roles = new ApiDataMixin({
  module: "employee",
  name: "roles",
  baseUrl: `${process.env.VUE_APP_MARIGOLD_API_PATH}/en/v1/roles`,
  initialValue: [],
  getters: {
    services({ roles }) {
      return unique(roles.map(({ service }) => service));
    },
    serviceRoles({ roles }) {
      return roles
        .map(x => ({
          ...x,
          text: x.name,
          value: x.id,
        }))
        .reduce((r, x) => {
          if (r.hasOwnProperty(x.service)) {
            r[x.service].push(x);
          } else {
            r[x.service] = [x];
          }
          return r;
        }, {});
    },
  },
});

employee.add(roles);

window.$employee = employee;

export default employee.toVuex;
